<template>
    <CModal 
        title="Download Report" 
        :show.sync="showModalDownloadFilter_" 
        color="info" 
        size="md" 
        @close="() => { showModalDownloadFilter_ = false }">
        <form @submit.prevent="approve">
			<div class="pl-3 pr-3 pb-3">
                <h5>Choose a filter to download a report</h5>
                <br>
        <CRow class="mb-2">
           <CCol lg="12">
				<label class="mb-1">Wo No.</label>
            <CInput  v-model="filter.wo_no" />
          </CCol>
          <CCol lg="12" class="mb-3" v-if="!is_trucking()">
            <label class="mb-1">Date Needed From</label>
            <Datepicker v-model="filter.date_needed_from" input-class="form-control"  />
          </CCol>
          <CCol lg="12" class="mb-3" v-if="!is_trucking()">
            <label class="mb-1">Date Needed To</label>
            <Datepicker v-model="filter.date_needed_to" input-class="form-control"  />
          </CCol>

          <CCol lg="12" class="mb-3" v-if="is_trucking()">
            <label class="mb-1">Request Date From</label>
            <Datepicker v-model="filter.date_needed_from" input-class="form-control" />
          </CCol>
          <CCol lg="12" class="mb-3" v-if="is_trucking()">
            <label class="mb-1">Request Date To</label>
            <Datepicker v-model="filter.date_needed_to" input-class="form-control" />
          </CCol>
          
         <CCol lg="12" class="mb-3" v-if="is_trucking()">
            <label class="mb-1">Depot</label>
          <v-select 
            label="setting_name" 
            :options="depotList.data"
            :reduce="item => item.id"
            v-model="filter.depot_id"
           
          > 
          </v-select> 
        </CCol>
         
         <CCol lg="12" class="mb-3" v-if="!is_trucking()">
            <label class="mb-1">Asset Type</label>
            <v-select 
            
              label="setting_name" 
              :options="[
                // {
                //       setting_name: 'All',
                //       id: 'all'
                //   },
                  {
                      setting_name: 'Vehicle',
                      id: 'vehicle'
                  },
                  {
                      setting_name: 'Equipment',
                      id: 'equipment'
                  },
                  {
                      setting_name: 'Furnishing',
                      id: 'furnishing'
                  }
              ]"
              :reduce="item => item.id"
              v-model="filter.asset_type"
              
            > 
            </v-select>  
          </CCol>
         <CCol lg="12" class="mb-3">
            <label class="mb-1" v-if="filter.asset_type == 'equipment'">Asset No.</label>
            <v-select 
              label="asset_no" 
              :options="equipmentList.data"
              placeholder="Asset No."
              :reduce="item => item.id"
              v-model="filter.asset_no"
              v-if="filter.asset_type == 'equipment'"
            > 
            </v-select>
            <label class="mb-1" v-if="filter.asset_type == 'furnishing'">Asset No.</label>  
            <v-select 
              label="asset_no" 
              :options="furnishingList.data"
              :reduce="item => item.id"
              v-model="filter.asset_no"
              placeholder="Asset No."
              v-if="filter.asset_type == 'furnishing'"
            > 
            </v-select>  
            <label class="mb-1" v-if="filter.asset_type == 'vehicle'">Plate No.</label>
            <v-select 
              label="plate_no" 
              :options="vehicleList.data"
              :reduce="item => item.id"
              v-model="filter.asset_no"
              placeholder="--Select--"
              v-if="filter.asset_type == 'vehicle'"
            > 
            </v-select>  
          </CCol>
         
           
        
          <CCol lg="12" class="mb-3" v-if="!is_trucking()"> 
            <label class="mb-1">Project/ Department </label>
            <v-select 
              label="customer_name" 
              :options="customerList.data"
              :reduce="item => item.id"
              v-model="filter.dept_id"
              placeholder="-Select-" 
             
            > 
            </v-select>  
          </CCol>
          <CCol lg="12" class="mb-3"  v-if="!is_trucking()">
            <label class="mb-1">WO Type</label>
            <v-select 
              label="setting_name" 
              :options="[
                  {
                      setting_name: 'Preventive Maintenance',
                      id: 'preventive maintenance'
                  },
                  {
                      setting_name: 'Repair',
                      id: 'repair'
                  },
                  {
                      setting_name: 'Change',
                      id: 'change'
                  },
                {
                  setting_name: 'Dismantling',
                  id: 'dismantling'
                }, 
                {
                  setting_name: 'Installation',
                  id: 'installation'
                },
                {
                  setting_name: 'Checking & Diagnose',
                  id: 'checking & diagnose'
                },
                {
                  setting_name: 'Rehabilitation',
                  id: 'rehabilitation'
                }, 
                {
                  setting_name: 'Recondition',
                  id: 'recondition'
                }, 
                  {
                      setting_name: 'Others',
                      id: 'others'
                  },
                  
              ]"
              :reduce="item => item.id"
              v-model="filter.wo_type"
              placeholder="-Select-"></v-select>
             
          </CCol>
          <CCol lg="12" class="mb-3">
                        <label class="mb-1">Status</label>
                        <v-select label="label" 
                        :options="status" 
                        :reduce="item => item.value" 
                        v-model="filter.status" 
                        placeholder="-Select-"></v-select>
        </CCol>
         
          
        
        </CRow>
        <CCol lg="12" class="mb-3" v-if="no_filter">
            <h6 class="text-danger">Please select a filter before downloading the report.</h6>
        </CCol>
            </div>
        </form>
        <div slot="footer" class="w-100" >

            
            <CButton class="float-right" size="sm" color="success" @click="download" v-if="!processing"><i class="fa fa-file"></i> Generate Report</CButton>
            <a :href="link_download" v-if="ready_download"><i class="fa fa-file"></i> Download Report</a>
            <CButton color="primary" disabled v-if="processing && !ready_download">
                <CSpinner component="span" size="sm" variant="grow" aria-hidden="true"/>
                Generating Report. Please wait ...
            </CButton>
            <CButton class="ml-1 mr-1 float-right" size="sm" color="light" @click="clear" v-if="!processing"><i class="fa fa-close"></i> Clear</CButton>
        </div>
    </CModal>
</template>

<script>
import config from '../config.js';
import Datepicker from 'vuejs-datepicker';
import axios from '../axios.js';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import moment from 'moment'
export default {
    components: { vSelect, Datepicker },
    mounted() {
        this.getEquipment();
          this.getFurnishing();
          this.getDepot();
          this.getVehicle();
         this.getCustomer();

         if(this.is_trucking()) { 
            this.filter.asset_type = 'vehicle';
         }
    },
    props: {
        showModalDownloadFilter: {required: true},
        filters: {}
    },
    data() {
        return {
            showModalDownloadFilter_:false,
            filter: {
				wo_no: '',
	            date_needed_from: '',
	            date_needed_to: '',
	            wo_type: '',
	            asset_type: '',
	            asset_no: '',
	            srf_no: '',
	            dept_id: '',
                status: 'all',
                depot_id : ''
			},
           
            status:[
              {
                    label: 'All',
                    value: 'all'
                },
        
                {
                    label: 'Draft',
                    value: 'draft'
                },

                {
                    label: 'Submitted',
                    value: 'submitted'
                },

                {
                    label: 'Approved',
                    value: 'approved'
                },
                {
                    label: 'On Service',
                    value: 'on service'

                },
                {
                    label: 'Completed',
                    value: 'completed'
                },
                {
                    label: 'Cancelled',
                    value: 'cancelled'
                },
                {
                    label: 'Declined',
                    value: 'declined'
                },
                
               
            ],
            equipmentList:[],
            furnishingList:[],
            depotList:[],
            vehicleList:[],
            customerList:[],
            no_filter:false,
            currentPage:1,
            processing:false,
            ready_download:false,
            link_download:'',
            report_downloaded: false
        }
    },
    methods: {
        async download() {
            // if(
            //     this.filter.wo_no ||
            //     this.filter.depot_id ||
            //     this.filter.date_needed_from ||
            //     this.filter.date_needed_to ||
            //     this.filter.wo_type ||
            //     this.filter.asset_type ||
            //     this.filter.status ||
            //     this.filter.asset_no ||
            //     this.filter.srf_no ||
            //     this.filter.dept_id 

               
            // ){
            this.report_downloaded = false;
                this.ready_download = false;
                this.processing = true;
                this.no_filter = false;
                var depot_id = this.filter.depot_id;
                if(depot_id == "all" || depot_id == null){
                    depot_id = ""
                }
                var dept_id = this.filter.dept_id;
                if(dept_id == "All" || dept_id == null){
                    dept_id = ""
                } 

                var date_needed_from = this.filter.date_needed_from;
                if(date_needed_from != ""){
                    date_needed_from = moment(date_needed_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
                }

                var date_needed_to = this.filter.date_needed_to;
                if(date_needed_to != ""){
                    date_needed_to = moment(date_needed_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
                    date_needed_to = date_needed_to + 86399; 
                }

                let url = 'reports/workOrder';
                if(this.is_trucking()) {
                    url = 'reports/workOrder/trucking';
                }
                
                await axios.post(`${config.api_path}/${url}`, {
                   
                        status : this.filter.status,
                        wo_no : this.filter.wo_no,
                        date_needed_from : date_needed_from,
                        date_needed_to : date_needed_to,
                        wo_type : this.filter.wo_type,
                        dept_id : this.filter.dept_id,
                        asset_type : this.filter.asset_type,
                        asset_no : this.filter.asset_no,
                        srf_no : this.filter.srf_no,
                        company_id : config.global_user_data.company.id,
                        depot_id : this.filter.depot_id
                  
                }).then(response=>{
                    // this.ready_download = false;
                    // this.processing = false;
                    this.link_download = config.main_path.replace('/index.php', '')+'/'+response.data.file
                    this.watchDownload(response.data.file);
                    
                    this.no_filter = false;
                })
                .catch(err => {
                    //  this.$showLoading(false)
                    this.no_filter = true;
                    this.ready_download = false;
                    this.processing = false;
                });
            // }
            // else{
            //     this.no_filter = true;
            //     this.ready_download = false;
            //     this.processing = false;
            // }
        },
        getDepot(){

            axios.get(config.api_path+'/reference/settings-list',{
                params:{
                setting_type:'depot'
                }
                })
            .then(response => {
            this.depotList = response.data; 
            })

            },
  
getEquipment(){

    axios.get(config.api_path+'/reference/equipment-list')
    .then(response => {
        this.equipmentList = response.data;
        this.equipmentList.data = this.equipmentList.data.map((value, index)=>{
            value.value = value.id;
            value.label = value.asset_no;
            return value;
        })
    })

},
 getFurnishing(){

    axios.get(config.api_path+'/reference/furnishing-list')
    .then(response => {
        this.furnishingList = response.data;
        this.furnishingList.data = this.furnishingList.data.map((value, index)=>{
            value.value = value.id;
            value.label = value.asset_no;
            return value;
        })
    })

},
 getCustomer(){
    axios.get(config.api_path+'/reference/customer-list', {
        params: {
            show_all : true
        }
    })
    .then(response => {
        this.customerList = response.data;    		
    })
}, 

getVehicle(){

    axios.get(config.api_path+'/reference/vehicle-list')
    .then(response => {
        let data = response.data.data;
        let holder = [];
        data.forEach(element => {
            if(element.plate_no && element.plate_no !== 'null') holder.push(element)
        });
        this.vehicleList.data = holder;
        this.vehicleList.data = this.vehicleList.data.map((value, index)=>{
            value.value = value.id;
            value.label = value.plate_no;
            return value;
        })
    })
},
        clear(){


            this.filter.wo_no = '';
            this.filter.depot_id = '';
            this.filter.date_needed_from = '';
            this.filter.date_needed_to = '';
            this.filter.wo_type = '';
            this.filter.asset_type = '';
            this.filter.status = 'all';
            this.filter.asset_no = '';
            this.filter.srf_no='';
            this.filter.dept_id='';

            if(this.is_trucking()) {
                this.filter.asset_type = 'vehicle'
            }
        },
        watchDownload(file){
            const self = this;
            self.interval_import_program = setInterval(function () {
                axios.get(`${config.api_path}/watch-download`,{
                    params : {
                        filename : file
                    }
                }).then(response => {
                    if(response.data.status){
                        self.ready_download = true;
                        self.processing = false;
                        clearInterval(self.interval_import_program);
                        if(!self.report_downloaded) {
                            self.report_downloaded = true;
                            window.open(config.main_path.replace('/index.php', '')+'/'+file)
                        }
                    }
                });
            }, 1000);
        }
    },
    watch: {
        showModalDownloadFilter_: function(val){
            this.$emit('closeModalDownloadFilter', val)
        },
        showModalDownloadFilter: function(){
            this.showModalDownloadFilter_ = this.showModalDownloadFilter;

            if(this.processing || this.ready_download)
                return;

            this.download()
        },
        filters: {
            deep: true,
            handler() {
                this.filter = {...this.filters}
                this.ready_download = false;
            }
        }
    },
}
</script>

<style>
.modal-header {
    background-color: white !important;
    
}
.modal-title {
    color: black;
}
</style>