<template>
	<div>
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
				<h5> Work Orders Report ({{count}})</h5>
			</CCol>
	     		 <CCol lg="6">
					  <CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i class="fa fa-search"></i>Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			        <!-- <CButton  
					  	v-if="config.getPermission('workorder_reports').download" 
							size="sm" style="margin-right: 10px" 
							color="btn btn-outline-primary"
							@click="() => { showModalDownloadFilter = true }"
							@click="download"
							class="float-lg-right">
							<i class="fa fa-download">
							</i>Download
					</CButton> -->
					<CButton 
							v-if="config.getPermission('workorder_reports').download"
							size="sm" style="margin-right: 10px" 
							color="btn btn-outline-primary"
							class="float-lg-right"
							@click="() => { showModalDownloadFilter = true }">
							<i class="fa fa-download"></i> Download
					</CButton>

			    </CCol>
		</CRow>
		<CRow> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @depot-search-query="search"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
		</CRow> 
		<hr>

			<CRow>			 
		      <CCol lg="12" >  
				<div class="table-responsive-sm table-responsive-md">
	      		<CTabs variant="pills" :active-tab="0">
	      			<CTab title="All" @click.native="tabChanged($event, 'all')">
				    	<br/>
						
				      	<CDataTable 
				      		 :loading="isLoading"
					      	:items="dataList.data"
							  :fields="company_type == 'trucking' ? fields_trucking : fields"
							hover
							outlined
							
					    >  
							
					    	<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
					      	</template>
							  <template #total_cost="{item}" >
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
									{{ item.labor_amount|computeTotal(item.work_order_part_request)|CurrencyFormatWithSign}}
         						 </td>
					      	</template>
					      
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPage"
				          	:pages="Math.ceil(dataList.total / 20)"
				          	:activePage="currentPage"
				          	@update:activePage="updatePage"
				        />
				    </CTab>
				    <CTab title="Draft"  @click.native="tabChanged($event, 'draft')">
				    	<br/>
						
				      	<CDataTable 
						 
					      	:items="dataListDraft.data"
							  :fields="company_type == 'trucking' ? fields_trucking : fields"
							hover
							striped
							:loading="isLoading"
							outlined
							
					    >   
						<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
					      	</template>
							  <template #total_cost="{item}" >
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
									{{ item.labor_amount|computeTotal(item.work_order_part_request)|CurrencyFormatWithSign}}
         						 </td>
					      	</template>
							  
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageDraft"
				          	:pages="Math.ceil(dataListDraft.total / 20)"
				          	:activePage="currentPageDraft"
				          	@update:activePage="updatePageDraft"
				        />
				    </CTab>
					<CTab title="Submitted"  @click.native="tabChanged($event, 'submitted')">
				    	<br/>
						
				      	<CDataTable 
				      		
					      	:items="dataListSubmitted.data"
							  :fields="company_type == 'trucking' ? fields_trucking : fields"
							hover
							striped
							:loading="isLoading"
							outlined
							
					    >   
							<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
					      	</template>
							  <template #total_cost="{item}" >
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
									{{ item.labor_amount|computeTotal(item.work_order_part_request)|CurrencyFormatWithSign}}
         						 </td>
					      	</template>
							  
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageSubmitted"
				          	:pages="Math.ceil(dataListSubmitted.total / 20)"
				          	:activePage="currentPageSubmitted"
				          	@update:activePage="updatePageSubmitted"
				        />
				    </CTab>

				    <CTab title="Approved"  @click.native="tabChanged($event, 'approved')">
				    	<br/>
				
				      	<CDataTable 
				      		
					      	:items="dataListApproved.data"
							  :fields="company_type == 'trucking' ? fields_trucking : fields"
							hover
							striped
							:loading="isLoading"
							outlined
					    >   
							<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
					      	</template>
							  <template #total_cost="{item}" >
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
									{{ item.labor_amount|computeTotal(item.work_order_part_request)|CurrencyFormatWithSign}}
         						 </td>
					      	</template>
							  
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageApproved"
				          	:pages="Math.ceil(dataListApproved.total / 20)"
				          	:activePage="currentPageApproved"
				          	@update:activePage="updatePageApproved"
				        />
				    </CTab>

				   
				    <CTab title="On Service"  @click.native="tabChanged($event, 'on service')">
				    	<br/>
						
				      	<CDataTable 
				      		
					      	:items="dataListOnService.data"
					      	:fields="company_type == 'trucking' ? fields_trucking : fields"
							hover
							striped
							:loading="isLoading"
							outlined
							
					    >   
							<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
					      	</template>
							  <template #total_cost="{item}" >
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
									{{ item.labor_amount|computeTotal(item.work_order_part_request)|CurrencyFormatWithSign}}
         						 </td>
					      	</template>
					      	
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageOnService"
				          	:pages="Math.ceil(dataListOnService.total / 20)"
				          	:activePage="currentPageOnService"
				          	@update:activePage="updatePageOnService"
				        />
				    </CTab>
					 <CTab title="Completed"  @click.native="tabChanged($event, 'completed')">
				    	<br/>
						
				      	<CDataTable 
				      		
					      	:items="dataListCompleted.data"
							  :fields="company_type == 'trucking' ? fields_trucking : fields"
							hover
							striped
							:loading="isLoading"
							outlined
					    >   
							<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
					      	</template>
							  <template #total_cost="{item}" >
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
									{{ item.labor_amount|computeTotal(item.work_order_part_request)|CurrencyFormatWithSign}}
         						 </td>
					      	</template>
					      	
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageCompleted"
				          	:pages="Math.ceil(dataListCompleted.total / 20)"
				          	:activePage="currentPageCompleted"
				          	@update:activePage="updatePageCompleted"
				        />
				    </CTab>

					<CTab title="Cancelled"  @click.native="tabChanged($event, 'cancelled')">
				    	<br/>
						
				      	<CDataTable 
				      		
					      	:items="dataListCancelled.data"
							:fields="company_type == 'trucking' ? fields_trucking : fields"
							hover
							striped
							:loading="isLoading"
							outlined
					    >   
							<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
					      	</template>
							  <template #total_cost="{item}" >
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
									{{ item.labor_amount|computeTotal(item.work_order_part_request)|CurrencyFormatWithSign}}
         						 </td>
					      	</template>
					      	
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageCancelled"
				          	:pages="Math.ceil(dataListCancelled.total / 20)"
				          	:activePage="currentPageCancelled"
				          	@update:activePage="updatePageCancelled"
				        />
				    </CTab>
 					<CTab title="Declined"  @click.native="tabChanged($event, 'declined')">
				    	<br/>
						
				      	<CDataTable 
				      		
					      	:items="dataListDeclined.data"
					      	:fields="company_type == 'trucking' ? fields_trucking : fields"
							hover
							striped
							:loading="isLoading"
							outlined
					    >   
						
							<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
					      	</template>
							  
							  <template #total_cost="{item}" >
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
									{{ item.labor_amount|computeTotal(item.work_order_part_request)|CurrencyFormatWithSign}}
         						 </td>
					      	</template>
					      
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageDeclined"
				          	:pages="Math.ceil(dataListDeclined.total / 20)"
				          	:activePage="currentPageDeclined"
				          	@update:activePage="updatePageDeclined"
				        />
				    </CTab>


				     
				</CTabs> 
				   </div>
			</CCol>
	    </CRow>
	
		</CCard>
		<download-filter :filters="filter" :showModalDownloadFilter="showModalDownloadFilter" @closeModalDownloadFilter="closeModalDownloadFilter"/>
	</div>
</template>
<script> 
import config from '../config.js';
import axios from '../axios';
import Swal from 'sweetalert2';
import Search from './work_order_search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
// import ApproveModal from './status_modal/approve';
// import OnServiceModal from './status_modal/onservice';
// import CompleteModal from './status_modal/complete';
import moment from 'moment';
import '../style.css';
import downloadFilter from './work_order_report_filter.vue';

export default {
	mounted(){  
		
		this.getData(); 
		
		for(var i = 0; i < document.querySelectorAll('.nav-item').length; i++){
			document.querySelectorAll('.nav-item')[i].onclick = ()=>{
				this.current_tab = document.querySelector(".nav-link.active").innerHTML;
				this.setCount();
			}
		}
		
		
	},
	data(){
		return{
			company_type: JSON.parse(window.localStorage.getItem("user_data")).company.company_type.includes("Trucking") ? "trucking" : JSON.parse(window.localStorage.getItem("user_data")).company.company_type,
			config,
			showModalDownloadFilter:false,
			isLoading: false,
			// approveModal: false,
			// onserviceModal: false,
			// deliverModal: false,
			// completeModal: false,
			formModal: false,
			showViewModal: false,
			dataList: [], 
			dataListDraft: [], 
			dataListApproved: [], 
			dataListSubmitted: [],
			dataListDeclined: [], 
			dataListOnService: [], 
			dataListDelivered: [], 
			dataListCompleted: [], 
			dataListCancelled: [], 
			current_tab: "All",
			count: 0,
			historyList: [],


			depotName: "", 
			fields: [
				{
					key: 'wo_no', 
					label: 'WO #'
				},
				{
					key: 'date_needed_label', 
					label: 'Date Needed'
				}, 
				{
					key: 'asset_type', 
					label: 'Asset Type'
				},
				{
					key: 'wo_type', 
					label: 'WO Type'
				},
				{
					key: 'dept_id_label', 
					label: 'Proj/Dept'
				},
				{
					key: 'depot_id_label', 
					label: 'Depot'
				},
				
				
				{
					key: 'asset_no_label', 
					label: 'Asset No.'
				},  
				 
				// {
				// 	key: 'srf_no_label', 
				// 	label: 'SRF No.'
				// },
				{
					key: 'plate_no', 
					label: 'Plate No.'
				}, 
				{
					key: 'service_summary_label', 
					label: 'Service Summary'
				},    
				{
					key: 'status', 
					label: 'Status'
				}, 
			  
				{
					key: 'part_total', 
					label: 'Part Cost'
				}, 
				{
					key: 'labor_total', 
					label: 'Labor Cost'
				}, 
				// {
				// 	key: 'action',
				// 	label: 'Action',
				// 	_style: { width: '20px'}
				// }
			],
			fields_trucking:  [
				{
					key: 'wo_no', 
					label: 'WO #'
				},
				{
					key: 'date_needed_label', 
					label: 'Request Date'
				}, 
				
				{
					key: 'depot_id_label', 
					label: 'Depot'
				},
				
				{
					key: 'plate_no', 
					label: 'Plate No.'
				}, 
				{
					key: 'scope_of_work', 
					label: 'Scope of Work'
				},   
				{
					key: 'total_cost', 
					label: 'Total Cost'
				},     
				{
					key: 'status', 
					label: 'Status'
				}, 
				
			],
			currentPage: 1, 
			currentPageDraft: 1,
			currentPageApproved: 1,
			currentPageSubmitted: 1,
			currentPageDeclined: 1,
			currentPageOnService: 1,
			currentPageDelivered: 1,
			currentPageCompleted: 1,
			currentPageCancelled: 1,


			editMode: false,
			selectedRow: {},
			showCollapse: false,
			filter: {
				wo_no: "",
	            date_needed_from: "",
	            date_needed_to: "",
	            wo_type: "",
	            asset_type: "",
	            asset_no: "",
	            srf_no: "", 
	            dept_id: "",
				status: ""
			},
			
			historyModal: false,
			moment
		}
	},
	name: 'Tables',
	// components: {Search, Datepicker, vSelect, ApproveModal, OnServiceModal, DeliverModal, CompleteModal},
	components: {Datepicker, vSelect, Search,downloadFilter},
	methods: {
		tabChanged(ev, type) {
			if(ev.target.className != 'nav-link active') return;

			this.filter.status = type;
			this.getData(type)
		},
		closeModalDownloadFilter(status){
			this.showModalDownloadFilter = status;
		},
		
		setCount(){
			if(this.current_tab == "All"){
				this.count = this.dataList.total
			}
			if(this.current_tab == "Draft"){
				this.count = this.dataListDraft.total
			}
			if(this.current_tab == "Submitted"){
				this.count = this.dataListSubmitted.total
			}
			if(this.current_tab == "Approved"){
				this.count = this.dataListApproved.total
			}
			if(this.current_tab == "Declined"){
				this.count = this.dataListDeclined.total
			}
			if(this.current_tab == "On Service"){
				this.count = this.dataListOnService.total
			}
			if(this.current_tab == "Delivered"){
				this.count = this.dataListDelivered.total
			}
			if(this.current_tab == "Completed"){
				this.count = this.dataListCompleted.total
			}
			if(this.current_tab == "Cancelled"){
				this.count = this.dataListCancelled.total
			}
		},
		reloadData(){
			this.getData(); 
			// this.getDataDraft();
			// this.getDataSubmitted();
			// this.getDataApproved();
			// this.getDataDeclined();
			// this.getDataOnService(); 
			// this.getDataCompleted();
			// this.getDataCancelled();
		},
		 getBadge (status) {
    	return status === 'completed' ? 'success'
       		 : status === 'draft' ? 'dark'
         	 : status === 'on service' ? 'warning'
			 : status === 'cancelled' ? 'secondary'
			 : status === 'approved' ? 'primary'
         	 : status === 'submitted' ? 'info'
             : status === 'declined' ? 'danger' : 'primary'
    },
		updatePage(data){
			this.currentPage = data;
			this.getData('all');
		},   

		updatePageDraft(data){
			this.currentPageDraft = data;
			this.getData('draft');
			// this.getDataDraft();
		},   

		updatePageApproved(data){
			this.currentPageApproved = data;
			this.getData('approved');
			// this.getDataApproved();
		}, 
			updatePageSubmitted(data){
			this.currentPageSubmitted = data;
			this.getData('submitted');
			// this.getDataSubmitted();
		},    

		updatePageDeclined(data){
			this.currentPageDeclined = data;
			this.getData('declined');
			// this.getDataDeclined();
		},   

		updatePageOnService(data){
			this.currentPageOnService = data;
			this.getData('on service');
			// this.getDataOnService();
		},   

		updatePageDelivered(data){
			this.currentPageDelivered = data;
			// this.getDataDelivered();
			this.getData('delivered');
		},   

		updatePageCompleted(data){
			this.currentPageCompleted = data;
			// this.getDataCompleted();
			this.getData('completed');
		},   

		updatePageCancelled(data){
			this.currentPageCancelled = data;
			this.getData('cancelled');
			// this.getDataCancelled();
		},   

	    toggleModal(){
	    	this.formModal = !this.formModal;
	    }, 

	    search(event){
	    	this.filter = event; 
	    	this.getData(this.current_tab.toLowerCase()); 
	    	// this.getDataDraft();
			// this.getDataSubmitted();
			// this.getDataApproved();
			// this.getDataDeclined();
			// this.getDataOnService(); 
			// this.getDataCompleted();
			// this.getDataCancelled();
	    }, 

	    getData(status = 'all'){  
			
			this.isLoading=true; 
			// let status = [null,'draft','submitted','approved','on service','completed','cancelled','declined'];
			// if(status) {
			status = (status == 'all') ? null : status;
			// }

			var date_needed_from = this.filter.date_needed_from;
			if(date_needed_from != ""){
	    		date_needed_from = moment(date_needed_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    	}

	    	var date_needed_to = this.filter.date_needed_to;
	    	if(date_needed_to != ""){
	    		date_needed_to = moment(date_needed_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
	    		date_needed_to = date_needed_to + 86399; 
	    	}

	    	var wo_type = this.filter.wo_type;
	    	if(wo_type == null){
	    		wo_type = "";
	    	}

	    	var asset_type = this.filter.asset_type;
	    	if(asset_type == null){
	    		asset_type = "";
	    	}

	    	var asset_no = this.filter.asset_no;
	    	if(asset_no == null){
	    		asset_no = "";
	    	}
	    	
	    	var dept_id = this.filter.dept_id;
	    	if(dept_id == null){
	    		dept_id = "";
	    	}

			let url = `${config.api_path}/workOrder`;
			let page = 1;

			switch(status) {
				case 'draft':
					page = this.currentPageDraft;
					break;
				case 'submitted':
					page = this.currentPageSubmitted;
					break;
				case 'approved':
					page = this.currentPageApproved;
					break;
				case 'on service':
					page = this.currentPageOnService;
					break;
				case 'completed':
					page = this.currentPageCompleted;
					break;
				case 'cancelled':
					page = this.currentPageCancelled;
					break;
				case 'declined':
					page = this.currentPageDeclined;
					break;
				default :
					page = this.currentPage;
					break;
			}

			let params = {
				status,
				page,
				limit : 20,
				wo_no : this.filter.wo_no,
				date_needed_from,
				date_needed_to,
				wo_type,
				asset_type,
				asset_no,
				srf_no : this.filter.srf_no,
				dept_id
			}

			axios.get(url, {params}).then(response => {
				// this.dataList = response.data; 
				let local_data = response.data.data.map((value, index)=>{
					// if(value.service_summary && value.service_summary != ""){
					// 	value.service_summary_label = value.service_summary
					// }
					// else{
					// 	value.service_summary_label = "N/A"
					// } 
					value.service_summary_label = value.service_summary ? this.$options.filters.truncate(value.service_summary):' ';
					value.scope_of_work = value.scope_of_work ? this.$options.filters.truncate(value.scope_of_work):' ';

					if(value.srf_no && value.srf_no != ""){
						value.srf_no_label = value.srf_no
					}
					else{
						value.srf_no_label = "N/A"
					} 
					if(value.depot_id){
	    				value.depot_id_label = value.depot?.setting_name ?? '';
	    			}
	    			else{
	    				value.depot_id_label = "-"
	    			}

					if(value.dept){
						value.dept_id_label = value.dept.customer_name
					}
					else{
						value.dept_id_label = "N/A"
					} 
					
					// value.plate_no = '-';
					// if(value.asset_type == "vehicle"){
					// 	value.asset_no_label = '-';
					// 	value.plate_no = value.vehicle?.plate_no ?? ' ';
					// }
					value.plate_no = '-';
					if (value.asset_type == "vehicle") {
						value.asset_no_label = value.vehicle?.asset_no ?? '';
						value.plate_no = value.vehicle?.plate_no ?? ' ';
					}
					else{
						if(value.furnishing_id){
							value.asset_no_label = value.furnishing?.asset_no ?? '-';
						}
						else{
							value.asset_no_label = value.equipment?.asset_no ?? '-';
						}
					}

					if(value.date_needed){
						value.date_needed_label = moment.unix(value.date_needed).format('MM/DD/YYYY')
					}
					else{
						value.date_needed_label = "N/A"
					} 
					var part_total = parseFloat(0);
				var labor_total = parseFloat(0);

				var part_request = value.work_order_part_request;
				for(var i = 0; i < part_request.length; i++){
					part_total += parseFloat(part_request[i].qty * part_request[i].unit);
				}
				value.part_total = part_total.toFixed(2);

				var labor_request = value.work_order_labor_request;
				for(var i = 0; i < labor_request.length; i++){
					labor_total += parseFloat(labor_request[i].qty * labor_request[i].unit);
				}
				value.labor_total = labor_total.toFixed(2);

				value.wo_type = value.wo_type?.toUpperCase() ?? '';
				value.asset_type = value.asset_type?.toUpperCase() ?? '';
					
				return value;
					
				}); 

				switch(status) {
					case 'draft':
						this.dataListDraft = response.data;
						this.dataListDraft.data = [...local_data];
						break;
					case 'submitted':
						this.dataListSubmitted = response.data;
						this.dataListSubmitted.data = [...local_data];
						break;
					case 'approved':
						this.dataListApproved = response.data;
						this.dataListApproved.data = [...local_data];
						break;
					case 'on service':
						this.dataListOnService = response.data;
						this.dataListOnService.data = [...local_data];
						break;
					case 'completed':
						this.dataListCompleted = response.data;
						this.dataListCompleted.data = [...local_data];
						break;
					case 'cancelled':
						this.dataListCancelled = response.data;
						this.dataListCancelled.data = [...local_data];
						break;
					case 'declined':
						this.dataListDeclined = response.data;
						this.dataListDeclined.data = [...local_data];
						break;
					default :
						this.dataList = response.data;
						this.dataList.data = [...local_data];
						break;
				}
				
				this.setCount()
			})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				
				this.isLoading = false;	
			})

	    	// axios.get(config.api_path+'/workOrder?page='+this.currentPage+'&limit=20&wo_no='+this.filter.wo_no+'&date_needed_from='+date_needed_from+'&date_needed_to='+date_needed_to+'&wo_type='+wo_type+'&asset_type='+asset_type+'&asset_no='+asset_no+'&srf_no='+this.filter.srf_no+'&dept_id='+dept_id)
	    	
	    },

	  
	    viewModal(item){ 
	    	this.$router.push('/main/view_work_order/'+item.id) 
	    }, 

	    openApproveModal(item){
	    	this.approveModal = true;
	    	this.selectedRow = item;
	    },

	    openOnServiceModal(item){
	    	this.onserviceModal = true;
	    	this.selectedRow = item;
	    },


	    openCompleteModal(item){
	    	this.completeModal = true;
	    	this.selectedRow = item;
	    },

	    updateModal(item){
	    	// this.$router.push('/main/update_booking/'+item.id)
	    	window.open('/#/main/update_work_order/'+item.id, '_blank');
	    },

	   
	    // download(){
	    // 	var current_tab = document.querySelector(".nav-link.active").innerHTML;
	    // 	var status = ""; 
	    // 	if(current_tab == 'Draft'){
	    // 		status = 'draft';
	    // 	}
	    // 	else if(current_tab == 'Submitted'){
	    // 		status = 'submitted';
	    // 	}
	    // 	else if(current_tab == 'Approved'){
	    // 		status = 'approved';
	    // 	}
	    // 	else if(current_tab == 'On Service'){
	    // 		status = 'on service';
	    // 	}
	    // 	else if(current_tab == 'Completed'){
	    // 		status = 'completed';
	    // 	}
	    // 	else if(current_tab == 'Cancelled'){
	    // 		status = 'cancelled';
	    // 	}
	    // 	else if(current_tab == 'Declined'){
	    // 		status = 'declined';
	    // 	}
	    // 	else{
	    // 		status = '';
	    // 	}

	    // 	var date_needed_from = this.filter.date_needed_from;
		// 	if(date_needed_from != ""){
	    // 		date_needed_from = moment(date_needed_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    // 	}

	    // 	var date_needed_to = this.filter.date_needed_to;
	    // 	if(date_needed_to != ""){
	    // 		date_needed_to = moment(date_needed_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
	    // 		date_needed_to = date_needed_to + 86399; 
	    // 	}

	    // 	var wo_type = this.filter.wo_type;
	    // 	if(wo_type == null){
	    // 		wo_type = "";
	    // 	}

	    // 	var asset_type = this.filter.asset_type;
	    // 	if(asset_type == null){
	    // 		asset_type = "";
	    // 	}

	    // 	var asset_no = this.filter.asset_no;
	    // 	if(asset_no == null){
	    // 		asset_no = "";
	    // 	}
		// 	var dept_id = this.filter.dept_id;
	    // 	if(dept_id == "all" || dept_id == null){
	    // 		dept_id = ""
	    // 	} 
	    // 	this.$showLoading(true)
	    // 	axios.get(config.api_path+'/reports/workOrder?status='+status
		// 	+'&wo_no='+this.filter.wo_no
		// 	+'&date_needed_from='+date_needed_from
		// 	+'&date_needed_to='+date_needed_to
		// 	+'&wo_type='+wo_type
		// 	+'&dept_id='+dept_id
		// 	+'&asset_type='+asset_type+'&asset_no='+asset_no+'&srf_no='+this.filter.srf_no) 
	    // 	.then(response=>{
		// 		this.$showLoading(false)
		// 		// console.log(response)
	    // 		window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 
	
		// 		setTimeout(() => {
		// 			axios.get(`${config.api_path}/remove-file`,{
		// 				params : {
		// 					filename : response.data.file
		// 				}
		// 			})	
		// 		}, 2000);


	    // 	})
		// 	.catch(err => {
		// 		this.$showLoading(false)
		// 	})
	    // }
 	}
}

</script>
